import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const domain = window.location.hostname === 'localhost' ? 'dev-76cznrsbmddl8u75.us.auth0.com' : 'dev-76cznrsbmddl8u75.us.auth0.com';
const clientId = window.location.hostname === 'localhost' ? 'Obwug10jggwWXFIswJVAjwVST7m65tGE' : 'Qo4puLXzE8uNNiI2WK2ctRGpNQB1o7XQ';
const redirectUri = window.location.origin;

root.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={redirectUri}
  >
    <App />
  </Auth0Provider>
);

reportWebVitals();