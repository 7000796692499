import './App.css';
import LoginButton from './components/loginButton';
import LogoutButton from './components/logoutButton';
import { useAuth0 } from "@auth0/auth0-react";
import Profile from './components/profile';

function App() {
  const { isAuthenticated } = useAuth0()
  return (
    <div className="App">
      { isAuthenticated
        ? (
          <div>
            <LogoutButton />
            <Profile />
          </div>
        )
        : <LoginButton />
      }
    </div>
  );
}

export default App;
